import * as React from 'react'
import {useEffect, useState} from "react";

export const App = () => {
    const [txt, setTxt] = useState("");

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch(
                "/hello"
            );
            const postsResp = await resp.text();
            // console.log(postsResp)
            setTxt(postsResp)
        };
        getPosts();

    }, []);



    return (
        <>
            <div>front end: Ready!</div>
            <div>{txt}</div>
        </>
    )
}
